<template>
  <div class="container">
    <div id="score-panel">
      <ul class="stars">
        <li
          v-for="star in stars"
          :key="star"
        ><i :class="star"></i></li>
      </ul>
      <span class="moves">{{ moves }} Moves</span>
      <div
        class="restart"
        @click="initGame"
      >
        <i class="fa fa-repeat"></i>
      </div>
    </div>
    <ul class="deck">
      <li
        v-for="(card, index) in cards"
        :key="index"
        :class="card.class"
        @click="flipCard(card)"
      >
        <div class="card-back"></div>
        <img :src="card.symbol" alt="Card image" class="card-image">

      </li>
    </ul>
  </div>
  <footer class="footer">
    <a href="//mykinkgames.com/" class="footer-link">Home</a>
    <a href="//mykinkgames.com/imprint" class="footer-link">Impressum</a>
  </footer>

</template>

<script>
import confetti from 'canvas-confetti';
import Swal from 'sweetalert2';


export default {
  data() {
    return {
      symbols: [
        require('@/assets/pics/1.png'), require('@/assets/pics/1.png'),
        require('@/assets/pics/2.png'), require('@/assets/pics/2.png'),
        require('@/assets/pics/3.png'), require('@/assets/pics/3.png'),
        require('@/assets/pics/4.png'), require('@/assets/pics/4.png'),
        require('@/assets/pics/5.png'), require('@/assets/pics/5.png'),
        require('@/assets/pics/6.png'), require('@/assets/pics/6.png'),
        require('@/assets/pics/7.png'), require('@/assets/pics/7.png'),
        require('@/assets/pics/8.png'), require('@/assets/pics/8.png'),
        require('@/assets/pics/9.png'), require('@/assets/pics/9.png'),
        require('@/assets/pics/10.png'), require('@/assets/pics/10.png'),
      ],
      cards: [],
      opened: [],
      match: 0,
      moves: 0,
      stars: ['fa fa-star', 'fa fa-star', 'fa fa-star'],
      gameCardsQTY: 10,
      rank3stars: 10,
      rank2stars: 14,
      rank1stars: 18
    };

  },
  methods: {
    shuffle(array) {
      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    },
    initGame() {
      this.cards = this.shuffle(this.symbols).map(symbol => {
        return { symbol: symbol, class: 'card' };
      });
      this.opened = [];
      this.match = 0;
      this.moves = 0;
      this.stars = ['fa fa-star', 'fa fa-star', 'fa fa-star'];
    },
    flipCard(card) {
      var scalar = 3;
      var unicorn = confetti.shapeFromText({ text: '🦄', scalar });

      var defaults = {
        spread: 360,
        ticks: 160,
        gravity: -0.5,
        decay: 0.96,
        startVelocity: 15,
        shapes: [unicorn],
        scalar
      };

      function shoot() {
        confetti({
          ...defaults,
          particleCount: 30
        });

        confetti({
          ...defaults,
          particleCount: 5,
          flat: true
        });

        confetti({
          ...defaults,
          particleCount: 15,
          scalar: scalar / 2,
          shapes: ['circle']
        });
      }

      if (this.opened.length > 1 || card.class.includes('match') || card.class.includes('open')) return;
      card.class = 'card open show';
      this.opened.push(card);
      if (this.opened.length === 2) {
        this.moves++;
        if (this.moves > this.rank1stars) {
          this.stars[0] = 'fa fa-star-o';
        } else if (this.moves > this.rank2stars) {
          this.stars[1] = 'fa fa-star-o';
        } else if (this.moves > this.rank3stars) {
          this.stars[2] = 'fa fa-star-o';
        }
        if (this.opened[0].symbol === this.opened[1].symbol) {
          this.opened.forEach(card => card.class = 'card match');
          this.match++;
          this.opened = [];
          if (this.match === this.gameCardsQTY) {
            setTimeout(shoot, 0);
            setTimeout(shoot, 100);
            setTimeout(shoot, 200);

            Swal.fire({
              title: 'Herzlichen Glückwunsch!',
              html: `Du hast gewonnen!<br>Benötigte Züge: <strong>${this.moves}</strong>`,
              animation: 'fadeIn',
              customClass: {
              popup: 'animated fadeIn'
            },
            confirmButtonText: 'Nochmal spielen?'
            }).then((result) => {
            if (result.isConfirmed) {
            this.initGame();
            }
            });
          }
        } else {
          setTimeout(() => {
            this.opened.forEach(card => card.class = 'card notmatch open show');
            setTimeout(() => {
              this.opened.forEach(card => card.class = 'card');
              this.opened = [];
            }, 1000);
          }, 100);
        }
      }
    }
  },
  mounted() {
    this.initGame();
  }
};
</script>

<style lang="scss">
$background: #524848;
$deck-background: #FFFA62;
$card-background: #FFCF7F;
$card_opend-background: #89C4FF;
$card_match-background: #9BCB3C;
$card_notmatch-background: #EE0E51;
$rainbow-gradient: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet);

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: $background;
  background: $rainbow-gradient;

  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  backface-visibility: hidden;
  transform: rotateY(0deg);
  border-radius: 8px;
}

.deck {
  width: 330px;
  margin: 0 auto;
  background: $deck-background;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 14px 14px 0 0 #000000;

  .card {
    height: 75px;
    width: 75px;
    background: $card-background;
    display: inline-block;
    margin: 0 10px 10px 0;
    line-height: 75px;
    font-size: 0;
    color: #ffffff;
    text-align: center;
    border-radius: 8px;
    vertical-align: top;
    cursor: pointer;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    transition: transform .3s ease;
    font-family: FontAwesome;

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(n+21) {
      margin-right: 15px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    &.open {
      transform: rotateY(0);
      background: $card_opend-background;
    }

    &.show {
      font-size: 33px;
    }

    &.match {
      transform: rotateY(0);
      background: $card_match-background;
      font-size: 33px;
    }

    &.notmatch {
      background: $card_notmatch-background;
    }
  }
}

#score-panel {
  text-align: left;
  width: 345px;
  margin-bottom: 10px;
  color: white;
  font-family: 'Courier New', Courier, monospace;

  .stars {
    margin: 0 5px 0 0;
    padding: 0;
    display: inline-block;

    li {
      list-style: none;
      display: inline-block;
    }
  }

  .restart {
    float: right;
    cursor: pointer;
  }
}

*::selection {
  background: transparent;
}

.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background: #4520b5;
  transform: rotateY(180deg);
  border-radius: 8px;
}

.footer {
  width: auto; // Setzen Sie die Breite auf auto
  padding: 10px 20px; // Fügen Sie seitliches Padding hinzu, um Platz zwischen den Links zu schaffen
  position: fixed;
  bottom: 0;
  left: 50%; // Zentriert den Footer horizontal
  transform: translateX(-50%); // Verschiebt den Footer zurück um die Hälfte seiner Breite
  display: flex;
  justify-content: center;
  align-items: center;

  .footer-link {
    margin: 0 15px;
    color: black;
    text-decoration: none;
    // transition: color 0.3s;
    &:not(:last-child) {
      &:after {
        content: '|';
        color: black;
        margin: 0 5px;
      }
    }
    font-family: 'Courier New', Courier, monospace;
    margin: 0 5px 0;

    &:hover {
      color: #4520b5; // Goldfarbe beim Überfahren
    }
  }
}
</style>